export enum shortMonths  {
    JAN,
    FEB,
    MAR,
    APR,
    MAY,
    JUN,
    JUL,
    AUG,
    SEP,
    OCT,
    NOV,
    DEC
}

export const iterateOverMonth = (
    startDate: Date,
    endDate: Date,
    iterator: (date: Date) => void) => {
    const date = removeTimeComponent(new Date(startDate));
    while (date < endDate) {
        iterator(date);
        date.setDate(date.getDate() + 1);
    }
};

export const convertDateToGqlDate = (date: string | number | Date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString("pt-br").split("/").reverse().join("-");
};

export const convertGqlDateToDate = (date: string) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
};

export const verboseDateToPickerCompatible = (dateString: string) => {
  let dateArray = dateString.split(" ");
  dateArray.shift()
  dateString = dateArray.join(", ")
  dateString = dateString.replace(',', '');
  dateString = dateString.replace('st', '');
  dateString = dateString.replace('nd', '');
  dateString = dateString.replace('rd', '');  
  dateString = dateString.replace('th', '');
  return dateString;
}

export const verboseDateToPickerCompatibleJa = (dateString: string) => {
  // The date format we get from the data is different depending on what the language setting is
  dateString = dateString.substring(0, dateString.length - 5);
  dateString = dateString.replace('年','-');
  dateString = dateString.replace('月','-');
  dateString = dateString.replace('日','');
  return dateString
}

export const getDateString = (x: Date) => {
    return `${x.getFullYear()}-${("0" + (x.getMonth() + 1)).slice(-2)}-${(
      "0" + x.getDate()
    ).slice(-2)}`;
  };

const checkForLeapYear = (x: Date) => {
    const year = x.getFullYear();

    if (year % 4 !== 0) {
        // Leap years must be divisible by 4.
        return false;
    }

    if (year % 100 === 0) {
        // Years divisible by 400 are leap years, but the other ones divisible by 100 aren't.
        if (year % 400 === 0) {
            return true;
        } else {
            return false;
        }
    }

    return true;
}

export const copyDate = (date: Date, dayOffset: number = 0) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + dayOffset);
}

// Calculate how many calendar rows should be displayed for a month containing the given date.
export const getNumberOfCalendarRows = (x: Date) => {
    const dateToCheck = new Date(x);
    dateToCheck.setDate(1);

    if (dateToCheck.getMonth() === 1) {
        //February handling
        if (
            !checkForLeapYear(dateToCheck) &&
            dateToCheck.getDay() === 0
        ) {
            //If a 28 day February starts on a Sunday, then only 4 rows are spanned.
            return 4;
        }
    } else if (
        dateToCheck.getMonth() === 8 || //September
        dateToCheck.getMonth() === 3 || //April
        dateToCheck.getMonth() === 5 || //June
        dateToCheck.getMonth() === 10 //November
    ) {
        //30 day months
        if (dateToCheck.getDay() === 6) {
            //If a 30 day month starts on a Saturday, the first and last days are on their own rows.
            return 6;
        }
    } else {
        //31 day months
        if (dateToCheck.getDay() === 6 || dateToCheck.getDay() === 5) {
            //If a 31 day month starts on a Saturday or Friday, it's a 6 row month.
            return 6;
        }
    }

    return 5;
}

export const getDateInIsoFormat = (date: string | Date) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
}

export const getFirstDayOfWeek = (date?: Date | string) => {
    let result = new Date();
    if (date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        result = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
    }

    result.setHours(0, 0, 0, 0);
    return result;
}

export const getLastDayOfWeek = (date?: Date) => {
    let result = new Date();
    if (date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        result = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 6);
    }

    result.setHours(0, 0, 0, 0);
    return result;
}

export const removeTimeComponent = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    return date;
}